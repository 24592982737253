@import "normalize.css";
@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap");

.color-blue {
  color: #26a0fc;
}
.color-green {
  color: #26e7a6;
}
.color-red {
  color: #fa541c;
}

body {
  margin: 0;
  font-family: "PT Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow: hidden;
  min-width: 360px;
  @media screen and (max-width: 1024px) {
    overflow: auto;
  }
}
.page-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.container {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
}
.Map {
  height: calc(100%);
  width: calc(100%);
  z-index: 1;
  overflow: hidden;
  position: relative;
  @media screen and (max-width: 1280px) {
    width: calc(100%);
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 50vh;
    min-height: 440px;
  }
  & > div:nth-child(2) {
    margin-top: -45px;
  }
}
.Header {
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  z-index: 1000;
  height: 75px;
  display: grid;
  grid-template-columns: 240px 1.7fr 190px;
  grid-template-rows: 1fr;
  gap: 1px 1px;
  grid-template-areas: ". . .";
  align-items: center;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  @media screen and (max-width: 1200px) {
    grid-template-columns: 240px 1.7fr 190px;
    height: auto;
    padding: 7px 0;
  }
  @media screen and (max-width: 1024px) {
    grid-template-columns: 220px 1.7fr 190px;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 1px 1px;
    grid-template-areas: "Header-info Header-support" "Header-buttons Header-buttons";
  }
  @media screen and (max-width: 479px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "Header-info Header-info" "Header-support Header-support" "Header-buttons Header-buttons";
  }
  &-info {
    padding-left: 20px;
    box-sizing: border-box;
    @media screen and (max-width: 768px) {
      grid-area: Header-info;
      padding-left: 15px;
    }
    @media screen and (max-width: 479px) {
      padding-left: 0;
    }
    &__title {
      font-size: 24px;
      line-height: 1;
      position: relative;
      @media screen and (max-width: 1200px) {
        font-size: 22px;
      }
      @media screen and (max-width: 479px) {
        margin: 0 auto;
      }
    }
    & .beta-version {
      position: absolute;
      left: 100%;
      top: -5px;
      margin-left: 5px;
      font-style: italic;
      font-size: large;
      width: fit-content;
      @media screen and (max-width: 1024px) {
        left: 190px;
      }
      @media screen and (max-width: 479px) {
        left: calc(50% + 100px);
      }
    }
    &__subtitle {
      color: #696969;
      font-size: 14px;
      line-height: 18px;
      display: none;
      & br {
        @media screen and (max-width: 1300px) {
          display: none;
        }
      }
      @media screen and (max-width: 1200px) {
        white-space: normal;
        line-height: 16px;
      }
      @media screen and (max-width: 479px) {
        font-size: 13px;
        line-height: 16px;
        margin-top: 5px;
        & br {
          display: none;
        }
      }
      @media screen and (max-width: 360px) {
        font-size: 12px;
      }
    }
  }
  &-buttons {
    padding-left: 140px;
    @media screen and (max-width: 1024px) {
      padding-left: 70px;
    }
    @media screen and (max-width: 768px) {
      padding-left: 0;
    }
    @media screen and (max-width: 768px) {
      text-align: center;
      grid-area: Header-buttons;
      margin-top: 10px;
    }
    @media screen and (max-width: 479px) {
      & button {
        padding: 4px 12px;
        font-size: 13px;
      }
    }
  }
  &-logos {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
    @media screen and (max-width: 768px) {
      grid-area: Header-logos;
      justify-content: flex-end;
    }
    @media screen and (max-width: 479px) {
      flex-direction: column;
      align-items: flex-end;
      padding-left: 10px;
    }
  }
  &-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
    @media screen and (max-width: 1200px) {
      margin-right: 15px;
    }
    @media screen and (max-width: 479px) {
      margin-right: 10px;
      & + & {
        margin-top: 10px;
      }
    }
    &__logo {
      display: block;
      width: 90px;
      @media screen and (max-width: 1200px) {
        width: 80px;
      }
      &_zk {
        width: 140px;
        @media screen and (max-width: 1200px) {
          width: 110px;
        }
      }
    }
    &__rosmol {
      display: block;
      width: 220px;
      @media screen and (max-width: 1200px) {
        width: 220px;
      }
      @media screen and (max-width: 1024px) {
        width: 190px;
      }
      @media screen and (max-width: 479px) {
        margin: 0 auto;
      }
    }
  }
  &-support {
    overflow: hidden;
    @media screen and (max-width: 768px) {
      grid-area: Header-support;
    }
    padding-right: 20px;
    @media screen and (max-width: 479px) {
      padding-left: 20px;
      box-sizing: border-box;
    }
    &__to-email {
      @media screen and (max-width: 479px) {
        text-align: center;
      }
      & br {
        @media screen and (max-width: 479px) {
          display: none;
        }
      }
    }
  }
}
.Filters {
  max-width: 100%;
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    grid-area: Filters;
  }
  &__items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    @media screen and (max-width: 1024px) {
      padding: 0 10px;
      margin-top: 10px;
    }
    @media screen and (max-width: 479px) {
      margin-top: 5px;
    }
  }
  &__item {
    width: 30%;
    max-width: 360px;
    @media screen and (max-width: 1024px) {
      width: calc(100% / 3 - 15px);
    }
    @media screen and (max-width: 479px) {
      width: 100%;
      max-width: 100%;
      margin-top: 5px;
    }
    &:not(:last-child) {
      margin-right: 15px;
      @media screen and (max-width: 479px) {
        margin-right: 0;
      }
    }
  }
}
.Filter {
  margin-top: 40px;
  &:first-child {
    margin-top: 20px;
  }
  &:last-child {
    margin-bottom: 20px;
  }
  &__title {
    font-weight: bold;
  }
  &__icon-filter {
    cursor: pointer;
    font-size: 16px;
    color: #26a0fc;
    margin-left: 5px;
    margin-top: -2px;
    display: inline-block;
    vertical-align: middle;
  }
  &-filters {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    &__items {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin-left: 3px;
    }
    &__item {
      margin: 0 3px;
      cursor: pointer;
      transition: 0.3s;
      border-bottom: 1px solid transparent;
      &_active {
        color: #008ffb;
        border-bottom: 1px solid;
      }
    }
  }
  &__more {
    text-align: center;
    cursor: pointer;
    color: rgb(0, 143, 251);
    border-bottom: 1px solid transparent;
    display: table;
    margin: 0 auto;
    transition: 0.2s;
    &:hover {
      border-bottom: 1px solid;
    }
  }
  &__loading {
    margin-top: 30px;
  }
}
.DataModeSwitch {
  position: absolute;
  top: 10px;
  left: 15px;
  z-index: 999;
  width: calc(100% - 560px);
  @media screen and (max-width: 1280px) {
    width: calc(100% - 440px);
  }
  @media screen and (max-width: 1100px) {
    width: 100%;
  }
  @media screen and (max-width: 479px) {
    top: 5px;
    left: 5px;
  }
  & button {
    margin: 3px;
    @media screen and (max-width: 479px) {
      height: 28px;
      line-height: 1;
      padding: 4px 10px;
      font-size: 13px;
    }
  }
}
aside {
  width: 540px;
  background: white;
  top: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.25);
  height: 92vh;
  overflow-y: scroll;
  @media screen and (max-width: 1280px) {
    width: 420px;
  }
  @media screen and (max-width: 1024px) {
    height: auto;
    width: 100%;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.25);
  }
  & h4 {
    font-weight: 400 !important;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 15px !important;
  }
}
.Sidebar {
  &__hint {
    color: #696969;
    text-align: center;
    width: 100%;
    @media screen and (max-width: 1024px) {
      width: 100%;
      max-width: 700px;
      margin: 0 auto;
    }
  }
  &__title {
    font-size: 140%;
    padding-top: 15px;
  }
  &__cancel {
    color: #696999;
    border: none;
    margin-left: 10px;
    width: 36px;
    height: 36px;
    cursor: pointer;
  }
}
.apexcharts-toolbar {
  display: none !important;
}
.r-charts {
  margin-top: 5px;
  margin-bottom: 10px;
  max-width: calc(100% - 20px);
  &-item {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    cursor: pointer;
    &:hover &__chart {
      transform: translateX(5px);
    }
    &:hover &__title {
      color: #008ffb;
    }
    &__title {
      flex-basis: 175px;
      font-size: 12px;
      text-align: right;
      padding-right: 15px;
      transition: 0.3s;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      overflow: hidden;
      @media screen and (max-width: 479px) {
        flex-basis: 120px;
      }
    }
    &__chart {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      flex-wrap: wrap;
      flex-basis: calc(100% - 175px);
      padding: 3px 0;
      border-top: 1px solid #e0e0e0;
      transition: 0.3s;
      @media screen and (max-width: 479px) {
        flex-basis: calc(100% - 120px);
      }
      &-group {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        width: 100%;
        &_center {
          justify-content: center;
        }
        &-col {
          flex-basis: calc(50% - 10px);
          display: flex;
          justify-content: flex-end;
          align-items: center;
          &_center {
            flex-basis: 30px;
            justify-content: center;
          }
          &_right {
            justify-content: flex-start;
          }
        }
        &-number {
          text-align: center;
          line-height: 1;
          margin: 0;
          padding: 0;
          font-size: 12px;
        }
      }
      &-line {
        background: #c3cbe6;
        height: 20px;
        font-size: 11px;
        line-height: 20px;
        min-width: fit-content;
        margin: 0;
        padding: 0 5px;
        box-sizing: border-box;
        text-align: center;
        overflow: hidden;
        max-width: 100%;
        color: #fff;
        text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
        &_gray {
          background: #c4c4c4;
        }
        &_dark-gray {
          background: #838383;
        }
      }
    }
  }
  &__more {
    text-align: center;
    cursor: pointer;
    color: rgb(0, 143, 251);
    border-bottom: 1px solid transparent;
    display: table;
    margin: 0 auto;
    margin-top: 10px;
    transition: 0.2s;
    &:hover {
      border-bottom: 1px solid;
    }
  }
  &-labels {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 5px;
  }
  &-label {
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 2px;
    &__color {
      width: 8px;
      height: 8px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 4px;
      border-radius: 2px;
      margin-top: -2px;
    }
    &__title {
      font-size: 13px;
      line-height: 19px;
    }
  }
  &__empty {
    text-align: center;
    margin-top: 15px;
  }
}
.ant-modal {
  min-width: calc(100% - 20px);
  max-width: calc(100% - 20px);
  top: 10px;
}
.ant-layout-sider-children {
  background-color: white;
  margin-left: 0;
}
.vacancy-modal {
  max-width: 100%;
  &__header {
    & p {
      margin-bottom: 0;
    }
  }
  &__checkbox {
    margin-top: 20px;
  }
  &__table {
    margin-top: 10px;
    .ant-table.ant-table-middle {
      @media screen and (max-width: 1024px) {
        overflow-x: auto;
      }
      @media screen and (max-width: 479px) {
        font-size: 12px;
      }
    }
    .ant-table-content {
      @media screen and (max-width: 1024px) {
        width: max-content;
      }
    }
  }
  &__loading {
    margin: 0 auto;
    margin-top: 30px;
    width: 100%;
  }
}
.vacancies {
  position: relative;
  display: inline-block;
  &__buttons {
    z-index: 999;
  }
  &__button {
    margin: 3px;
  }
}
.MapHeader {
  position: absolute;
  top: 0;
  left: 10px;
  width: calc(100% - 20px);
  z-index: 2;
  &__items {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    @media screen and (max-width: 1060px) {
      justify-content: center;
    }
    @media screen and (max-width: 479px) {
      font-size: 12px;
    }
  }
  &__item {
    &_left {
    }
    &_center {
    }
    &_right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      padding-right: 10px;
      @media screen and (max-width: 1060px) {
        flex-direction: row;
        flex-basis: 100%;
      }
      @media screen and (max-width: 479px) {
        flex-direction: column;
        align-items: center;
        margin-top: 5px;
      }
    }
  }
}
.MapFooter {
  position: absolute;
  bottom: 15px;
  left: 0;
  width: 100%;
  z-index: 2;
  @media screen and (max-width: 1200px) {
    bottom: 45px;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    bottom: 5px;
  }
  @media screen and (max-width: 479px) {
    background: #fff;
    padding: 5px;
    bottom: 0;
  }
  &__items {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    @media screen and (max-width: 1060px) {
      justify-content: center;
    }
    @media screen and (max-width: 479px) {
      font-size: 12px;
    }
  }
  &__item {
    &_left {
    }
    &_center {
    }
    &_right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      padding-right: 10px;
      @media screen and (max-width: 1060px) {
        flex-direction: row;
        flex-basis: 100%;
      }
      @media screen and (max-width: 479px) {
        flex-direction: column;
        align-items: center;
        margin-top: 5px;
      }
    }
  }
}
.Sources {
  background: rgba(255, 255, 255, 0.5);
  padding: 2px 5px;
  margin: 3px;
  margin-left: 5px;
  @media screen and (max-width: 479px) {
    background: rgba(255, 255, 255, 0.8);
  }
}
.MapLegend {
  &__container {
    margin: 2px;
    margin-top: 10px;
  }
  &__marker {
    display: inline-block;
    vertical-align: middle;
    padding: 2px 5px;
    background-color: #fff;
    position: relative;
    &-icon {
      background-image: url("/img/marker.png");
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      width: 20px;
      height: 24px;
      z-index: 1;
      display: inline-block;
      vertical-align: top;
      @media screen and (max-width: 479px) {
        width: 14px;
        height: 20px;
      }
    }
    &-count {
      font-size: 14px;
      font-weight: bold;
      display: inline-block;
      vertical-align: top;
    }
  }
  &__title {
    display: inline-block;
    vertical-align: middle;
    padding: 0 5px;
  }
}
.MapLegendGradient {
  width: 200px;
  @media screen and (max-width: 479px) {
    width: 150px;
  }
  &__line {
    width: calc(100% - 35px);
    height: 6px;
    margin: 0 auto;
    margin-bottom: 20px;
    position: relative;
  }
  &__items {
  }
  &__item {
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    font-size: 12px;
    padding-top: 8px;
    @media screen and (max-width: 479px) {
      font-size: 10px;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 1px;
      height: 10px;
      background-color: #333;
    }
  }
}
.disclaimer {
  padding: 2px 5px;
  display: inline-block;
  vertical-align: middle;
  &__title {
    text-align: center;
    cursor: pointer;
    color: rgb(0, 143, 251);
    border-bottom: 1px solid transparent;
    display: table;
    margin: 0 auto;
    transition: 0.2s;
    &:hover {
      border-bottom: 1px solid;
    }
  }
  &-modal {
    &__text {
      & ul {
        list-style: none;
        padding: 0;
        margin: 0;
        & li {
          padding: 0;
          margin: 0;
          padding-left: 15px;
        }
      }
    }
  }
}
.education {
  position: relative;
  display: inline-block;
  &__buttons {
    z-index: 999;
  }
  &__button {
    margin: 3px;
  }
  &-modal {
    &__title {
      font-size: 26px !important;
    }
    &__icon {
      font-size: 20px;
      margin-right: 6px;
    }
  }
}
.help {
  &__button {
    float: right;
    @media screen and (max-width: 1140px) {
      float: inherit;
    }
  }
  &-modal {
    &__title {
      font-size: 20px;
      line-height: 26px;
      &_center {
        text-align: center;
      }
    }
    &__thumb-wrap {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      & iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-width: 0;
        outline-width: 0;
      }
    }
    &__more {
      font-size: 16px;
      line-height: 22px;
      margin-top: 15px;
      &-link {
        text-align: center;
        cursor: pointer;
        color: rgb(0, 143, 251);
        border-bottom: 1px solid transparent;
        transition: 0.2s;
        &:hover {
          border-bottom: 1px solid;
        }
      }
    }
    &-form {
      & .ant-form-item-label {
        width: 100%;
        text-align: left;
      }
    }
  }
}
.ant-btn:hover {
  border-color: #61cac5;
  color: #61cac5;
}
.ant-btn-primary {
  background: #61cac5;
  border-color: #61cac5;
  &:hover {
    background: #2a4d8b;
    border-color: #2a4d8b;
  }
  &:active {
    background: #61cac5;
    border-color: #61cac5;
  }
  &:focus {
    background: #61cac5;
    border-color: #61cac5;
  }
}
.main {
  display: grid;
  grid-template-columns: 380px 1fr 380px;
  grid-template-rows: 1fr;
  gap: 1px 1px;
  grid-template-areas: ". . .";
  align-items: stretch;
  width: 100%;
  height: calc(100vh - 75px);
  @media screen and (max-width: 1440px) {
    grid-template-columns: 320px 1fr 320px;
  }
  @media screen and (max-width: 1024px) {
    display: block;
    height: auto;
  }
}
.sidebar {
  overflow-y: scroll;
  background: #fff;
  padding: 20px 15px;
  height: 100%;
  box-sizing: border-box;
  @media screen and (max-width: 1024px) {
    height: auto;
  }
  &_left {
    border-right: 1px solid rgba(0, 0, 0, 0.25);
  }
  &_right {
    border-left: 1px solid rgba(0, 0, 0, 0.25);
  }
  &__filter-icon {
    display: none;
    margin-bottom: 0px;
    vertical-align: middle;
    @media screen and (max-width: 1024px) {
      display: inline-block;
    }
  }
}
.modal {
  &-rows {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    &__row {
      flex-basis: calc(100% / 3);
      overflow: hidden;
      @media screen and (max-width: 1200px) {
        flex-basis: calc(100% / 2);
      }
      @media screen and (max-width: 1024px) {
        flex-basis: calc(100%);
      }
    }
  }
}
